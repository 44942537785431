import React from 'react'

const ClutchReview = () => {
    const htmlSnippet = '<script type="text/javascript" src="https://widget.clutch.co/static/js/widget.js"></script> <div class="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="1" data-height="40" data-nofollow="true" data-expandifr="true" data-scale="100" data-clutchcompany-id="2202513"></div>';
  return (
    
    <>
<div className='clutch-review-container' dangerouslySetInnerHTML={{ __html: htmlSnippet }} />
    </>
   
   
  )
}

export default ClutchReview
