import Hero from "../Componets/Hero";
import Navbar from "../Componets/Navbar";
import Footer from "../Componets/Footer";
// import CareerPage from "../Componets/CareerPage";

import vbg from "../images/service-banner-handshake.mov"

import CareerPage from "../Componets/CareerPage";
import ClutchReview from "../Componets/ClutchReview";
import TrustpilotReviews from "../Componets/TrustPilot";





function Career() {
    return (
      <>
      <Navbar />
      <Hero
        cName="hero-career"
        video={vbg}
        heroH1="services-H1"
      />
      <CareerPage />
      <ClutchReview/>
      <TrustpilotReviews/>
      <Footer/>
      </>
    );
  }
  export default Career;