import "./TripStyles.css";
import TripData from "./TripData";
import Aos from "aos";
import "aos/dist/aos.css"
import React, {  useEffect } from "react";

function Trip() {
  useEffect(()=>{
    Aos.init({duration: 2000})
  },[])
  return (
    <div className="trip">
    
      <h2 data-aos="fade-up" data-aos-duration="4000">Our Business Capabilities</h2>

      <div className="tripcard">
       <TripData
    
          image="https://wallpapers.com/images/high/diagram-of-wise-business-strategy-apwdyqsj0n9k8i1e.webp"
          heading="STRATEGY"
          text="At PTL, we specialize in providing customized technology solutions to businesses of all sizes. Our services include technology assessments,  cloud services, IT support, and software development. We are committed to building strong relationships with our clients and delivering high-quality services that align with their goals and budget. Contact us today to learn more about how Profusion Tech Labs can help your business achieve success through technology."
          link="https://example.com/complete-content-stratege"
        />
        <TripData
              

          image="https://wallpapercave.com/wp/wp10226755.jpg"
          heading="DECISION SUPPORT"
          text="Our decision support platform provides data-driven insights to help businesses make better decisions. With data visualization, analysis, predictive analytics, and reporting, our platform is easy to use and secure. Contact us today to learn more about how our platform can help your business succeed."
        />
        <TripData
               

          image="https://c0.wallpaperflare.com/preview/811/935/645/adults-analysis-brainstorming-collaboration.jpg"
          heading="DELIVERY"
          text="With our short-term project management and contracting services. We specialize in providing quick and effective solutions for your business needs, so you can focus on what you do best. Whether you need a project manager, consultant, or contractor, we're here to help you make it happen. Contact us today to learn more about how we can support your success."
        
         

             


        />
      </div>
    </div>
    
  );
}
export default Trip;
