import { Component } from "react";
import Aos from "aos";
import "aos/dist/aos.css"
class HomeData extends Component {
  componentDidMount() {
    // Initialize AOS
    Aos.init({ duration: 2000 });
  }
  render() {
    return (
      <>
        <div className="course-col" data-aos="fade-up" data-aos-duration="2000">
          <h3>{this.props.heading}</h3>
          <p>{this.props.pText}</p>
        </div>
      </>
    );
  }
}
export default HomeData;
