import "../Componets/HeroStyles.css";
import React, {useEffect} from "react";
import Aos from "aos";
import "aos/dist/aos.css"
function Hero(props) {
  useEffect(()=>{
    Aos.init({duration: 2000})
  },[])
  return (
    <>
      <div className={props.cName} >
        <video src={props.video} autoPlay muted loop />
      </div>
      <div className={props.heroH1} data-aos="fade-up" data-aos-duration='2000'>
        <h1>{props.title1}</h1>
       

      </div>
    </>
  );
}
export default Hero;
