import "./App.css";
import Home from "./routes/Home";
import { Route, Routes } from "react-router-dom";
import Career from "./routes/Career";
import Contact from "./routes/Contact";
import Services from "./routes/Services";
// import Whatsapp from "./Componets/Whatsapp";
import Calendly from "./Componets/Calendly";


function App() {
  return (
    <>
   <div className="App">
   
   <Routes>
    <Route path="/" element={ <Home />}/>
    <Route path="/Services" element={ <Services />}/>
    <Route path="/contact" element={ <Contact />}/>
    <Route path="/career" element={ <Career />}/>
    <Route path="/calendly" element={ <Calendly/>}/>

   </Routes>
   {/* <Whatsapp/> */}
   </div>
   
       </>
  );
}
export default App;
