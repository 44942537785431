import "../Componets/HomeContent.css";
import HomeData from "./HomeData";
import Aos from "aos";
import "aos/dist/aos.css"
import React, {useEffect } from "react";

const HomeContent = () => {
  useEffect(()=>{
    Aos.init({duration: 2000})
  },[])
  return (
    <div className="course" data-aos="fade-up" data-aos-duration="2000">
      <h1 data-aos="fade-up" data-aos-duration="4000">Our Specialities</h1>
      <p data-aos="fade-up" data-aos-duration="6000">Innovative IT Solutions for Business Efficiency</p>
      <div className="row">
        <HomeData
          heading="Welcome"
          pText="Welcome to PTL, a leading provider of IT consulting and outsourcing services. Our mission is to help businesses of all sizes stay competitive in today's fast-paced digital world by providing expert guidance and support for all their IT needs."
        />
        <HomeData
          heading="Expertise"
          pText="At PTL, we understand that every business is unique. That's why we offer a wide range of IT consulting and outsourcing services, tailored to meet the specific needs of each of our clients. Whether you need help with software development, data management, or network security, our team of experts has the skills and experience to get the job done."
        />
        <HomeData
          heading="Passion"
          pText="At PTL, we are passionate about technology and its ability to drive business success. Our goal is to empower your business to succeed in today's digital world by providing expert IT consulting and outsourcing services."
        />
        <HomeData
          heading="Success"
          pText="We're proud to have helped many businesses achieve their goals, and we're always looking for new challenges. If you're looking for a reliable and experienced IT consulting and outsourcing partner, look no further than Profusion Tech Labs."
        />
      </div>
    </div>
  );
};
export default HomeContent;







