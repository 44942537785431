import Footer from "../Componets/Footer";
import Hero from "../Componets/Hero";
import HomeContent from "../Componets/HomeContent";
import HomeDesign from "../Componets/HomeDesign";
import Navbar from "../Componets/Navbar";
import Trip from "../Componets/Trip";
import VBg2 from "../images/home-banner-superAI.mov";

import ServicePage from "../Componets/ServicePage";
// import ClutchReview from "../Componets/ClutchReview";
// import TrustpilotReviews from "../Componets/TrustPilot";


function Home() {
 
  return (
    <>
      <Navbar />
      <Hero
        cName="hero"
        video={VBg2}
        heroH1="home-H1"
        title1="Your Trusted  Partner for IT Consulting & Outsourcing Services"
      />
         {/* <ClutchReview/> */}
         {/* <TrustpilotReviews/> */}
      <Trip />
      <ServicePage />
      
      <HomeContent />
      <HomeDesign />
       
      <Footer />
    </>
  );
}
export default Home;
