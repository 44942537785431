import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./NavbarStyle.css";
import logo from "../images/logo-blue.png"
import logo2 from "../images/logo-white.png"
function Navbar() { 
    const [over, setOver]= useState("");
  const [fix, setFix]= useState(false)
  const [color, setColor]=useState("");
    // Function to handle navbar behavior when scrolling
    function setFixed(){
        if (window.scrollY >=40) {
            setFix(true)
            setColor("black")
            setOver({logo})
        }else{
            setFix(false)
            setColor("")
            setOver("")
        }

    }
        // Add event listener for scroll and cleanup on unmount
    useEffect (()=>{window.addEventListener('scroll', setFixed, true);
  return()=>window.removeEventListener("scroll", setFixed);
  },[])
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  return (
    <>
      <nav className={fix ? "navbar fixed":"navbar"}>
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
         <img src={over ? logo:logo2}  alt="" />
          
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className={fix ? "nav-links black": "nav-links"}
            style={{color:color}}
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/services"
                activeClassName="active"
                className={fix ? "nav-links black": "nav-links" }
            style={{color:color}}
                onClick={handleClick}
              >
                Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className={fix ? "nav-links black": "nav-links"}
            style={{color:color}}
                onClick={handleClick}
              >
                Contact
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/career"
                activeClassName="active"
                className={fix ? "nav-links black": "nav-links"}
            style={{color:color}}
                onClick={handleClick}
              >
                Career
              </NavLink>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fa-solid fa-circle-xmark": "fas fa-bars"}></i>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
