import "./Footer.css";

import logo from "../images/logo-white.png";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { AiFillTwitterSquare } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
// import { icons } from "react-icons";
import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";

const Footer = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="footer" data-aos="fade-up" data-aos-duration="2000">
      <div className="footer-data">
        <div>
          <img
            src={logo}
            alt="img"
            data-aos="fade-up"
            data-aos-duration="2000"
          />
        </div>
        <div data-aos="fade-up" data-aos-duration="2000">
          <a
            href="https://www.linkedin.com/company/profusion-tech-labs/ "
            target="_blank"
            rel="noreferrer"
          >
            <AiFillLinkedin className="icon" />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100094489697037&mibextid=ZbWKwL"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillFacebook className="icon" />
          </a>

          <a
            href="https://twitter.com/Profusion1419?t=q_DFNAJjo5MDZV8htYeX_w&s=09"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillTwitterSquare className="icon" />
          </a>

          <a
            href="https://www.instagram.com/profusiontechlabs/?igshid=MzNlNGNkZWQ4Mg%3D%3D"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillInstagram className="icon" />
          </a>
        </div>
      </div>
      <div>
        <p>Copyright 2023 © All rights Reserved</p>
      </div>
    </div>
  );
};
export default Footer;
