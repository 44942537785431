// import Hero from "../Componets/Hero";
import Navbar from "../Componets/Navbar";
import Footer from "../Componets/Footer";
import ContactForm from "../Componets/ContactForm";
// import vbg from "../images/contact-banner2.mp4";

function Contact() {
  return (
    <>
      <Navbar />
      {/* <Hero cName="hero-contact" video={vbg} heroH1="services-H1" /> */}
      <ContactForm />
      <Footer />
    </>
  );
}
export default Contact;
