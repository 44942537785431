// import "./ServiceStyle.css"

// function ServiceData(props){
    
//     return(
//         <div className="t-card">
//             <div className="t-image">
//                 <img src={props.image} alt="" />
//             </div>
//             <h4>{props.heading}</h4>
//             <p>{props.text} <br /><br />
//             <p>{props.text2}</p><br />
//             <p>{props.text3}</p><br />
//             <p>{props.text4}</p>
//             <p>{props.text5}</p>

//             </p>         
//         </div>
//     )
// }
// export default ServiceData
import React, { useState, useEffect } from "react";
import "./ServiceStyle.css";
import Aos from "aos";
import "aos/dist/aos.css"
function ServiceData(props) {
  useEffect(()=>{
    Aos.init({duration: 2000})
  },[])
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="t-card" data-aos="fade-up" data-aos-duration="2000">
      <div className="t-image">
        <img src={props.image} alt="" />
      </div>
      <h4>{props.heading}</h4>
      {expanded ? (
        <>
          <p>{props.text}</p>
          <p>{props.text2}</p>
          <p>{props.text3}</p>
          <p>{props.text4}</p>
          <p>{props.text5}</p>
          <button onClick={toggleExpanded} className="read-less-button">
            Read Less
          </button>
        </>
      ) : (
        <>
          <p>
            {expanded ? props.text : props.text.slice(0, 120) + "..."}
          </p>
          <button onClick={toggleExpanded} className="read-more-button">
            Read More...
          </button>
        </>
      )}
    </div>
  );
}

export default ServiceData;



