import React from 'react';
import "./CareerPageStyle.css"
const CareerPage = () => {
  const jobList = [
    {
      title: 'Software Engineer',
      location: 'Location: Remote',
      description: 'We are looking for a skilled software engineer to join our team...',
      requirements: [
        'Bachelor\'s degree in Computer Science or related field',
        'Proficiency in JavaScript, HTML, CSS',
        'Experience with React.js or other modern JavaScript frameworks',
        'Strong problem-solving skills',
        // Add more requirements as needed
      ]
    },
    {
      title: 'Data Analyst',
      location: 'Location: New York, NY',
      description: 'We are seeking a talented data analyst to analyze and interpret...',
      requirements: [
        'Bachelor\'s degree in Computer Science, Statistics, or related field',
        'Experience with data analysis tools such as Python, R, or SQL',
        'Familiarity with data visualization techniques',
        'Strong analytical and problem-solving skills',
        // Add more requirements as needed
      ]
    },
    // Add more job objects as needed
    {
      title: 'Software Engineer',
      location: 'Location: Remote',
      description: 'We are looking for a skilled software engineer to join our team...',
      requirements: [
        'Bachelor\'s degree in Computer Science or related field',
        'Proficiency in JavaScript, HTML, CSS',
        'Experience with React.js or other modern JavaScript frameworks',
        'Strong problem-solving skills',
        // Add more requirements as needed
      ]
    },
    {
      title: 'Data Analyst',
      location: 'Location: New York, NY',
      description: 'We are seeking a talented data analyst to analyze and interpret...',
      requirements: [
        'Bachelor\'s degree in Computer Science, Statistics, or related field',
        'Experience with data analysis tools such as Python, R, or SQL',
        'Familiarity with data visualization techniques',
        'Strong analytical and problem-solving skills',
        // Add more requirements as needed
      ]
    },
    // Add more job objects as needed
 
  
  ];

  return (
    <div className="career-page">
      <h1>We are always looking for talented individuals to join our team. Explore our job opportunities below</h1>
      <div className="job-listings">
        {jobList.map((job, index) => (
          <div className="job-card" key={index}>
            <h2>{job.title}</h2>
            <h3>{job.location}</h3>
            <p>{job.description}</p>
            {job.requirements && (
              <>
                <h3>Requirements:</h3>
                <ul>
                  {job.requirements.map((requirement, index) => (
                    <li key={index}>{requirement}</li>
                  ))}
                </ul>
              </>
            )}
            {/* <button>Apply Now</button> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CareerPage;
