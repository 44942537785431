import Hero from "../Componets/Hero";
import Navbar from "../Componets/Navbar";
import Footer from "../Componets/Footer";
import ServicePage from "../Componets/ServicePage";

import VBg from "../images/growth-banner.mov";
import ClutchReview from "../Componets/ClutchReview";

function Services() {
  return (
    <>
      <Navbar />
      <Hero cName="hero-service" video={VBg} heroH1="services-H1" />
      <ServicePage />
      <ClutchReview/>
      <Footer />
    </>
  );
}
export default Services;
