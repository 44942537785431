
import { Component } from "react";
import { Link } from "react-router-dom";
import CalendlyPage from "./CalendlyPage";
import Aos from "aos";
import "aos/dist/aos.css"

class HomeDesignData extends Component {
  componentDidMount() {
    // Initialize AOS
    Aos.init({ duration: 2000 });
  }
  render() {
   
    return (

      <>
         
        <div className="carrer"  data-aos="fade-up" data-aos-duration="2000">
          <h1 data-aos="fade-up" data-aos-duration="4000">Careers At PTL</h1>
          <p data-aos="fade-up" data-aos-duration="6000">
            Unleash Your Inner Spark: <br />
            Join Our Team for a Thriving Career
          </p>
          <Link to={"/career"} className="hero-btn" data-aos="fade-up" data-aos-duration="8000">
            HIRE ME
          </Link>
        </div>
      <CalendlyPage/>
        <div className="cta" data-aos="fade-up" data-aos-duration="2000">
          <h1 data-aos="fade-up" data-aos-duration="4000">
            We're Here to Serve You! Get in Touch <br /> for Assistance or Collaborations
          </h1>
          <div className="button-container">
          <Link to={"/contact"} className="hero-btn-2" data-aos="fade-up" data-aos-duration="8000">
            Contact Us
          </Link>
          </div>
         
        </div>
      </>
    );
  }
}
export default HomeDesignData;

