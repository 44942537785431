import "./WorkingHoursAndHolidays.css";
import React ,{useEffect}from "react";
import Aos from "aos";
import "aos/dist/aos.css"

const WorkingHoursAndHolidays = () => {
  useEffect(()=>{
    Aos.init({duration: 2000})
  },[])
  const workingHours = {
    Monday: "  9:00 AM - 5:00 PM",
    Tuesday: "9:00 AM - 5:00 PM",
    Wednesday: "9:00 AM - 5:00 PM",
    Thursday: "9:00 AM - 5:00 PM",
    Friday: "9:00 AM - 5:00 PM",
    Saturday: "Closed",
    Sunday: "Closed"

  };

  const holidays = [
    { date: "2023-01-16", event: "Martin Luther King Jr. Day" },
    { date: "2023-02-20", event: "Presidents day" },
    { date: "2023-05-29", event: "Memorial Day" },
    { date: "2023-07-04", event: "Independence Day" },
    { date: "2023-09-04", event: "Labor Day" },
    { date: "2023-11-23", event: "Thanksgiving Day" },
    { date: "2023-11-24", event: "Day After Thanksgiving" },
  ];

  return (
    <div className="working-hours-and-holidays-wrapper" data-aos="fade-up" data-aos-duration="2000">
      <div className="working-hours-and-holidays-container">
        <h2 data-aos="fade-up" data-aos-duration="2500">Working Hours</h2>
        <ul className="working-hour-ul" data-aos="fade-up" data-aos-duration="2500">
          {Object.entries(workingHours).map(([day, hours]) => (
            <li className="working-hours-list" key={day}>
              <br />
              {day}: {hours}
            </li>
          ))}
        </ul>
      </div>

      <div className="working-hours-and-holidays-container">
        <h2 data-aos="fade-up" data-aos-duration="1000">List of Holidays</h2>
        <table className="holidays-table">
          <thead>
            <tr data-aos="fade-up" data-aos-duration="1000">
              <th>Date</th>
              <th>Day</th>
              <th>Event</th>
            </tr>
          </thead>
          <tbody data-aos="fade-up" data-aos-duration="2000">
            {holidays.map(({ date, event } ) => (
              <tr key={date}>
                <td>{new Date(date).toLocaleDateString("en-US")}</td>
                <td>
                  {new Date(date).toLocaleDateString("en-US", {
                    weekday: "long",
                  })}
                </td>
                <td>{event}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WorkingHoursAndHolidays;

// Code with see more and see less button
// import "./WorkingHoursAndHolidays.css";
// import React, { useState } from "react";

// const WorkingHoursAndHolidays = () => {
//   const workingHours = {
//     Monday: "9:00 AM - 5:00 PM",
//     Tuesday: "9:00 AM - 5:00 PM",
//     Wednesday: "9:00 AM - 5:00 PM",
//     Thursday: "9:00 AM - 5:00 PM",
//     Friday: "9:00 AM - 5:00 PM",
//   };

//   const holidays = [
//     { date: "2023-01-16", event: "Martin Luther King Jr. Day" },
//     { date: "2023-02-20", event: "Presidents day" },
//     { date: "2023-05-29", event: "Memorial Day" },
//     { date: "2023-07-04", event: "Independence Day" },
//     { date: "2023-09-04", event: "Labor Day" },
//     { date: "2023-11-23", event: "Thanksgiving Day" },
//     { date: "2023-11-24", event: "Day After Thanksgiving" },
//   ];

//   const [showWorkingHours, setShowWorkingHours] = useState(false);
//   const [showHolidays, setShowHolidays] = useState(false);

//   const toggleWorkingHours = () => {
//     setShowWorkingHours((prevState) => !prevState);
//   };

//   const toggleHolidays = () => {
//     setShowHolidays((prevState) => !prevState);
//   };

//   return (
//     <div className="working-hours-and-holidays-wrapper">
//       <div className="working-hours-and-holidays-container">
//         <h2>Working Hours</h2>
//         {showWorkingHours ? (
//           <>
//             <ul>
//               {Object.entries(workingHours).map(([day, hours]) => (
//                 <li className="working-hours-list" key={day}>
//                   {day}: {hours}
//                 </li>
//               ))}
//             </ul>
//             <button className="buttons-container" onClick={toggleWorkingHours}>See Less</button>
//           </>
//         ) : (
//           <button className="buttons-container" onClick={toggleWorkingHours}>See More</button>
//         )}
//       </div>

//       <div className="working-hours-and-holidays-container">
//         {" "}
//         <h2>List of Holidays</h2>
//         {showHolidays ? (
//           <>
//             <table className="holidays-table">
//               <thead>
//                 <tr>
//                   <th>Date</th>
//                   <th>Day</th>
//                   <th>Event</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {holidays.map(({ date, event }) => (
//                   <tr key={date}>
//                     <td>{new Date(date).toLocaleDateString("en-US")}</td>
//                     <td>
//                       {new Date(date).toLocaleDateString("en-US", {
//                         weekday: "long",
//                       })}
//                     </td>
//                     <td>{event}</td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//             <button className="buttons-container" onClick={toggleHolidays}>
//               See Less
//             </button>
//           </>
//         ) : (
//           <button className="buttons-container" onClick={toggleHolidays}>
//             See More
//           </button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default WorkingHoursAndHolidays;

