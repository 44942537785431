import React from 'react'
import HomeDesignData from './HomeDesignData'
import "./HomeDesignStyle.css"
const HomeDesign = () => {
  return (
    <div>
      <HomeDesignData />
    </div>
  )
}

export default HomeDesign
